@import './_includes/normalize';

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import './_includes/variables';
@import './_includes/mixins';



html {
    min-height: 100% !important;
}
html {
    /* FONT SIZES */
    font-size:14px;
}

body {
    height:auto;
    /*min-height:0px !important;*/
    font-family: $_STDFont;
    &.nav-is-open{
	    height:100%;
	    overflow:hidden;
	}
}


/* GENERAL */
h1, h2, h3, h4, h5, h6 {
	font-family: $_STDFont;
	color: $COPYGREY;
	font-weight: $normal;
	border:none;
	margin:0px 0px 0px 0px;
	padding:7px 0px 7px 0px;
	text-transform:none;
	line-height:1;
	line-height:1em;
	a {
		text-decoration:none;
		color:inherit;
	}
}
h1 { font-size:40px; }
h2 { font-size:30px; }
h3 { font-size:25px; }
h4 { font-size:20px; }
h5 { font-size:14px; }
h6 { font-size:10px; }
a:focus, a:active {
   background-color:inherit;
   color:inherit;
}

p, li, td, ul, label, input, button, select, .href-btn {
	font-family: $_STDFont;
	color: $COPYGREY;
	font-weight: $normal;
	font-size: 14px;
	margin:0px 0px 0px 0px;
	padding:7px 0px 7px 0px;
	text-transform:none;
	line-height:1;
	line-height:1em;
	box-sizing:border-box;
	a, a:active, a:visited {
		color: $THEMECOLOR;
	}
}


p,img,table,td,tr,div,section,article,header,video,input,a { background-repeat:no-repeat; }
p,div,section,header,input,textarea,select,article { box-sizing: border-box; }

p strong, li strong, h1 strong, h2 strong, h3 strong, td strong, button strong {
	font-weight: $bold;
}




/* ====================================================================================================== */
.cTHEME { color: $THEMECOLOR !important; }
.cLTHEME { color: lighten( $THEMECOLOR, 30% ); }
.cDTHEME { color: darken( $THEMECOLOR, 20% ); }
.cBLACK { color: $BLACK !important; }
.cRED { color: $RED !important; }
.cGREEN { color: $CGREEN !important; }
.cDGREY { color: $CDGREY !important; }
.cLGREY { color: lighten( $BLACK, 50% ); }
.cORANGE { color: $CORANGE; }

/*
	BACKGROUND COLOURS */
.bgTHEME { background-color: $THEMECOLOR !important; }
.bgLTHEME { background-color: lighten( $THEMECOLOR, 30% ); }
.bgDTHEME { background-color: darken( $THEMECOLOR, 20% ); }
.bgBLACK { background-color: $BLACK !important; }
.bgRED { background-color: $RED !important; }
.bgGREEN { background-color: $CGREEN !important; }
.bgLGREY { background-color: lighten( $BLACK, 65% ); &:hover { background-color: lighten( $BLACK, 35% ); } }
.bgORANGE { background-color: $CORANGE; }
.bgLORANGE { background-color: lighten( $CORANGE, 20% ); }


.bgORANGE, .bgRED, .bgBLACK, .bgDTHEME {
	h1,h2,h3,h4,h5,h6,p {
		color: $WHITE;
	}
}

button, .href-btn {
	text-align:center;
	border:1px solid $WHITE;
	padding-left:15px;
	padding-right:15px;
	border-radius: $bRADIUS $bRADIUS $bRADIUS $bRADIUS;
	text-decoration:none;
	display:flex;
	align-items:center;
	justify-content: center;
}

header, button, .href-btn {
	&.bgTHEME, &.bgLTHEME, &.bgDTHEME, &.bgBLACK, &.bgRED, &.bgGREEN {
		color: $WHITE;
		h1, h2, h3, h4, h5, h6, p, li {
			color: $WHITE;
		}
	}
	&.bgLGREY {
		color: lighten($BLACK,90%);
		h1, h2, h3, h4, h5, h6, p, li {
			color: lighten($BLACK,90%);
		}
	}
}
header, button, .href-btn {
	&.bgTHEME { border-color: lighten( $THEMECOLOR, 30% ); }
	&.bgLTHEME { border-color: darken( $THEMECOLOR, 10% ); }
	&.bgDTHEME { border-color: $THEMECOLOR; }
	&.bgBLACK { border-color: lighten( $BLACK, 30% ); }
	&.bgRED { border-color: lighten( $RED, 30% ); }
	&.bgGREEN { border-color: lighten( $CGREEN, 30% ); }
	&.bgLGREY { border-color: $BORDERGREY }
	&.bgORANGE { border-color: $CORANGE }
}
button, .href-btn {
	&.bgTHEME {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
	&.bgLTHEME {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
	&.bgDTHEME {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
	&.bgBLACK {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
	&.bgRED {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
	&.bgGREEN {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
	&.bgLGREY {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
	&.bgORANGE {
		&:hover {
			background-color: $BTNHOVERGREY !important;
		}
	}
}




/*
	main container div */
body {
	min-height:100vh;
	background-color: lighten( $BLACK, 92% );
}
.page-wrap {
	height:100vh;
	position:relative;
	width:100%;
	border:0px solid $RED;
	

	
}
.page-content-cont {
	min-height:100vh;
	width:90%;
	position:relative;
	left:5%;
	margin-left:0;
	background-color: transparent;
	border:0px solid $RED;
	@include breakWidth(1600) {
		width:90%;
		margin-left:0;
	}
	@include breakWidth(1280) {
		width:80%;
		left:10%;
		margin-left:0;
	}
}



.container {
	position:relative;
	border:1px solid $BORDERGREY;
	border-radius: $bRADIUS $bRADIUS $bRADIUS $bRADIUS;
	@include alpha-attribute('background-color', rgba($WHITE,0.6),$WHITE);
	header {
		display:flex;
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		-o-flex-direction: row;
		flex-direction: row;
		justify-content: space-between;
		-ms-align-items: center;
		align-items: center;
		padding:0 5px 0 15px;
		border-radius: $bRADIUS 0 0 0;
		div:nth-child(1) {
		}
		div:nth-child(2) {
			display:flex;
			/*flex-direction:column;*/
			justify-content: flex-end;
			/*align-items:flex-end;*/
			
		}
		button {
			margin:0 3px 0 3px;
		}
		&.top-tab-bar {
			button {
				border-width:0 1px 2px 1px;
				border-radius:0 0 $bRADIUS $bRADIUS;
			}
		}
	}
	.content {
		position:relative;
		padding:15px 15px 15px 15px;
		border-radius: 0 $bRADIUS 0 $bRADIUS;
		hr {
			width:100%;
		}
	}
}

/* -------------------------------------------------
	FLEX and column sizes -------------------------- */

/* col sizes */
.col1 {
	width:300px;
	.content {
		button {
			width:100%;
		}
	}
}
.col2 {
	width:600px;
}
.colPerc50 {
	width:50%;
}
.col3 {
	width:800px;
}
.col4 {
	width:1024px;
}
.colfull {
	width:95%;
}
.formRow {
	display:flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
	/*justify-content: space-between;*/
	-ms-align-items: center;
	align-items: center;
	padding:15px 0 0 0;
	width:100%;
}

.iFlex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.flexRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
		flex-wrap: wrap;
	flex-direction: row;

	box-sizing:border-box;
	&.sides {
		justify-content: space-between;
	}
	&.stretch {
		align-content: stretch;
	}
	&.center {
		align-content: center;
	}
	&.center_center {
		justify-content: center;
		align-content: center;
	}
	&.noWrap {
		flex-wrap:nowrap;
	}
}
.flexCol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		box-sizing:border-box;
		&.sides {
		justify-content: space-between;
	}
	&.stretch {
		align-content: stretch;
	}
	&.center {
		align-content: center;
	}
	&.center_center {
		justify-content: center;
		align-content: center;
	}
	/*box-sizing: border-box;*/
}
.sizeW10 { width: 10%; }
.sizeW20 { width: 20%; }
.sizeW25 { width: 25%; }
.sizeW30 { width: 30%; }
.sizeW33 { width: 33.33333%; }
.sizeW40 { width: 40%; }
.sizeW45 { width: 45%; }
.sizeW49 { width: 49%; }
.sizeW50 { width: 50%; }
.sizeW60 { width: 60%; }
.sizeW70 { width: 70%; }
.sizeW80 { width: 80%; }
.sizeW90 { width: 90%; }
.sizeW95 { width: 95%; }
.sizeW100 { width: 100%; }
/*
	cols = 11
	space width: 0.1% = total 10 spaces
	box width = 9%
	*/
.col11 { width:100%; margin-left:0 !important; margin-right:0 !important; }
.col10 { width:90%; }
.col9 { width:81%; }
.col8 { width:72%; }
.col7 { width:63%; }
.col6 { width:54%; }
.col5 { width:45%; }
.col4 { width:36%; }
.col3 { width:27%; }
.col2 { width:18%; }
.col1 { width:9%; }
.m-left { margin-left:0.1%; }
.m-right { margin-right:0.1%; }
.m-none { margin-left:0%; margin-right:0%; }
@include breakWidth(760) {
	.col11 { width:100%; margin-left:0 !important; margin-right:0 !important; }
	.col10 { width:100%; }
	.col9 { width:100%; }
	.col8 { width:100%; }
	.col7 { width:100%; }
	.col6 { width:100%; }
	.col5 { width:100%; }
	.col4 { width:100%; }
	.col3 { width:100%; }
	.col2 { width:100%; }
	.col1 { width:100%; }
	.m-left { margin-left:0%; }
	.m-right { margin-right:0%; }
	.m-none { margin-left:0%; margin-right:0%; }
}
@include breakWidth(600) {
	&.col1, &.col2, &.col3, &.col4, &.colfull {
		width:95%;
		.content {
			button {
				width:100%;
			}
		}
	}
}
/* -------------------------------------------------
	end of -- FLEX and column sizes ---------------- */






/*
	PAGE SECTION
	*/
.page-section, .content-section {
	display:block;
	position:relative;
	width:100%;
	height:auto;
	margin:0 0 0 0;
	padding:0 0 0 0;
	box-sizing: border-box;
	clear:both;

	background-size:cover;
 	background-position:center center;
 	background-repeat:no-repeat;
 	&.center {
		display:flex;
		justify-content: center;
		-ms-align-items: center;
		align-items: center;
 	}

 	@include breakWidth(600) {
 		padding:15px 0 0 0;
 		&.center {
 			align-items:flex-start;
 		}
 	}
}
.content-section {
	padding:20px 30px 60px 30px;
}
